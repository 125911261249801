import { useEffect} from "react";
import { Link, useLocation} from 'react-router-dom';
import "./navBar.css";

const NavBar = () => {

    /*************************************************************************************************************************/

    const thisLocation = useLocation();

    useEffect(() => {
       
        const menu = document.querySelector(".contMenu");               //Oculta el Menu en Home
        const actualUrl = window.location.href;
        if (actualUrl.includes("home")) {
            menu.classList.add("displayOff");
        } else {
            menu.classList.remove("displayOff");
        }
                   
    }, [thisLocation]);


    /****************************************************************************************************************************/

    useEffect(() => {
        const menu = document.querySelector(".contMenu");               //Cambia el color del navbar en ilustraciones para chicos
        const menuMin = document.querySelector(".menu");
        const actualUrl = window.location.href;
        if (actualUrl.includes("g1") || actualUrl.includes("g2")) {
            menu.classList.add("backGroundChilds");
            if(menuMin) menuMin.classList.add("backGroundChilds");
        } else {
            menu.classList.remove("backGroundChilds");
            if(menuMin) menuMin.classList.remove("backGroundChilds");
        }
        window.scrollTo({top: 0});                                     //Al cambiar de pagina lleva el scroll hacia arriba 
    }, [thisLocation]);


    /****************************************************************************************************************************/
           
    useEffect(() => {

        const iconoMenu = document.querySelector(".iconoMenu");
        const menu = document.querySelector(".menu");
        const opciones = document.getElementsByClassName("opcion");
        let initialWidth = window.innerWidth;
        let REM;
        let breakPoint;
        let menuBajo = false;
        let flagEnterMenuToggle = true;
        iconoMenu.addEventListener("click", () => {                         //Manejo de clases para bajar y subir menu
            if (flagEnterMenuToggle) {
                flagEnterMenuToggle = false;
                if (!menuBajo) {
                    menu.classList.remove("menuSube", "menuBaja");
                    menu.classList.add("menuBaja");
                    menuBajo = !menuBajo;    
                    const animations = document.getAnimations();
                    animations[0].addEventListener("finish", () => {
                        flagEnterMenuToggle = true;
                    })
                } else {
                    menu.classList.remove("menuBaja", "menuSube");
                    menu.classList.add("menuSube");
                    menuBajo = !menuBajo;  
                    const animations = document.getAnimations();
                    animations[0].addEventListener("finish", () => {
                        flagEnterMenuToggle = true;
                    })
                }
            }
        })

        const calcularREM = () => {
            if (window.innerWidth >= window.innerHeight) REM = 0.01 * window.innerHeight + 10;
            if (window.innerWidth < window.innerHeight) REM = 0.01 * window.innerWidth + 10;
            breakPoint = 60 * REM;
        }
         
        calcularREM();
        if(window.innerWidth < breakPoint) {                                        //Cargamos página con un ancho menor a BreakPoint
            menu.classList.add("iconoMenuON", "menuOFF");
            iconoMenu.classList.add("iconoMenuON");
            for (const opcion of opciones) {
                opcion.classList.add("opcion2");
            }
        }
        
        window.addEventListener("resize", () => {                                   //Manejo de clases al hacer resize o cambio de orientacion
            calcularREM();           
            if(initialWidth < breakPoint && window.innerWidth > breakPoint) {       //Pasamos de un ancho de pantalla menor a breakPoint a uno mayor
                menu.classList.remove("menuSube", "menuBaja", "menuOFF");
                iconoMenu.classList.remove("iconoMenuON");
                for (const opcion of opciones) {
                    opcion.classList.remove("opcion2");
                }
                menuBajo = false;
                initialWidth = window.innerWidth;
            }     
            if(initialWidth > breakPoint && window.innerWidth < breakPoint) {       //Pasamos de un ancho de pantalla mayor a breakPoint a uno menor
                menu.classList.add("menuOFF");
                iconoMenu.classList.add("iconoMenuON");
                for (const opcion of opciones) {
                    opcion.classList.add("opcion2");
                }
                initialWidth = window.innerWidth;
            }     
        })

        window.addEventListener("click", (e) => {                                   //Cerramos en menu desplegado al hacer click en cualquier lado menos en el icono de menu
            if (e.target.getAttribute("name") !== "iconoMenu" && menuBajo === true) {
                menu.classList.remove("menuBaja", "menuSube");
                menuBajo = false;
            }
        })
       
        // eslint-disable-next-line
    }, []);    
    
    return (
        <div className="contMenu flex">
            <img className="iconoMenu" src="/images/icons/menu.png" alt="Icono Menu" name="iconoMenu"/>
            <div className="tapaMenu"></div>
            <div className="menu flex" name="menu">
                <Link className="opcion flex" to="/">Home</Link>
                <Link className="opcion flex" to="/gallerylist">Mi Trabajo</Link>
                <Link className="opcion flex" to="/about">Sobre Mí</Link>
                <Link className="opcion flex" to="/contact">Contacto</Link>
                <Link className="opcion flex" to="/press">Prensa</Link>
                {/* <Link className="opcion flex" to="/tools">Mis Herramientas</Link> */}
            </div>
        </div>
    )  
}   

export default NavBar;
