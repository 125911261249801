import React from 'react';
import "./photographyIndex.css";
import BgParticles from '../../components/bgParticles/BgParticles';
import { Link } from 'react-router-dom';

const PhotographyIndex = () => {
    return (
        <>
            <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.25}/>
            <div className='contSeccion contIllustrationsIndex opacityOnCharge animation-duration1 flex wrap'>
                <Link to="/gallerys/g5" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>01</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDescPhotos1'>"Las máquinas de la alegría"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt illustrationsIndexImgContIntPhotos'>
                        <img src="/images/drawings/g6/4.jpg" className='illustrationsIndexImg illustrationsIndexImgPhoto' alt="Gallery Img" />
                        <div className='illustrationsIndexImgPhotosFoco flex'>
                            <p className='illustrationsIndexImgPhotosFocoTexto'>Cámara Pentax 35mm</p>
                        </div>
                    </div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'>"¿Cuánto tiempo es para siempre? A veces, solo un segundo"</span>
                        <span>Lewis carrol, Alicia en el país de las maravillas</span> <br /> 
                    </p>
                </Link>
                <Link to="/gallerys/g6" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>02</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDescPhotos2'>"En alguna frontera"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt illustrationsIndexImgContIntPhotos'>
                        <img src="/images/drawings/g7/2.jpg" className='illustrationsIndexImg illustrationsIndexImgPhoto' alt="Gallery Img" />
                        <div className='illustrationsIndexImgPhotosFoco flex'>
                            <p className='illustrationsIndexImgPhotosFocoTexto'>Cámara pentax 35mm y Nikon D3000</p>
                        </div>
                    </div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'>"Que se sientan Abrumados, pero totalmente al día en cuanto a información, entonces tendrán la sensación de que piensan"</span>
                        <span>Ray Bradbury, Farenheit 451</span> <br />
                    </p>
                </Link>
                <Link to="/gallerys/g7" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>03</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDescPhotos3'>"Fotografía digital"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt illustrationsIndexImgContIntPhotos'>
                        <img src="/images/drawings/g8/5.jpeg" className='illustrationsIndexImg illustrationsIndexImgPhoto' alt="Gallery Img" />
                        <div className='illustrationsIndexImgPhotosFoco flex'>
                            <p className='illustrationsIndexImgPhotosFocoTexto'>Cámara Nikon D3000</p>
                        </div>
                    </div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'>"En cierto modo la vida en el rascacielos empezaba a parecerse al mundo exterior: la misma crueldad implacable enmascarada por una serie de convenciones corteses"</span>
                        <span>"J. G. Ballard, Rascacielos (1975) </span> <br />
                    </p>
                </Link>
            </div>
        </>
    );
}

export default PhotographyIndex;
