const aosDrawingCard = () => {

    const asignAnimation = (card, quick) => {
        if (!card.getAttribute("class").includes("animationOn")) {                      //Para que no entre si la card ya tiene una animación cargada
            card.classList.add("opacityOn");
            card.classList.add("animationOn");                                          //Marcador "animationOn" para indicar que la animación está en proceso
            if (!quick) {
                card.querySelector(".drawingCardImgCont").classList.add("drawingHeightY");      //Animacion normal
                card.querySelector(".drawingCardImg").classList.add("drawingScaleIn");
            } else {
                card.querySelector(".drawingCardImgCont").classList.add("drawingHeightYQuick"); //Animacion rapida
                card.querySelector(".drawingCardImg").classList.add("drawingScaleInQuick");
            }

            const drawingCardImgCont = card.querySelector(".drawingCardImgCont");
            const cardAnimation = drawingCardImgCont.getAnimations()[0];
            cardAnimation.addEventListener("finish", () => {
                card.classList.add("animationFinish");                                  //Marcador "animationFinish" para indicar que la animación terminó
                card.querySelector(".drawingCardImg").classList.add("pointerEventsOn"); //Habilitamos eventos de puntero sobre la imagen al terminar la animacion
                AosDrawingCard();                                                       //Al terminar la animacion llamammos a la funcion AosDrawingCard() para cargar nuevos elementos
            })
        }
    }

    const AosDrawingCard = () => {
        const drawingCards = document.querySelectorAll(".drawingCardCont");
        const cardsInViewPort = [];
        drawingCards.forEach((card) => {          
            if(((card.getBoundingClientRect().top ) <= (window.innerHeight  - (card.offsetHeight / 2)))) {     
                cardsInViewPort.push(card);                                                                    //Cargamos en un array solo los elementos de los que se vió su primer mitad
            }  
            if (card.getBoundingClientRect().top < 0) {         //Las cards que tocan el borde superior del viewport se animan rapido, para que las que vienen despues no tarden
                asignAnimation(card, true);
            }
        })

        for (const card of cardsInViewPort) {
            if (!card.getAttribute("class").includes("animationOn")) {              //1º Caso: elemento todavía sin animación
                asignAnimation(card);                                               //Lo animamos
                break;                                                              //Salimos del for
            } else if ((card.getAttribute("class").includes("animationOn")) && (card.getAttribute("class").includes("animationFinish"))) {  //2º Caso: Elemento con animación terminada. Pasamos al siguiente.
                continue;
            } else if ((card.getAttribute("class").includes("animationOn")) && (!card.getAttribute("class").includes("animationFinish"))) { //3º Caso: Elemento en plena animación. Salimos del for
                break;
            }
        }
    }

    window.addEventListener("resize", AosDrawingCard);
    window.addEventListener("orientationchange", AosDrawingCard);
    window.addEventListener("scroll", AosDrawingCard);
    AosDrawingCard();

}

export default aosDrawingCard;