import React from 'react';
import "./footer.css";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Footer = () => {
    const thisLocation = useLocation();

    useEffect(() => {
        const footerItems = document.querySelectorAll(".delayOnCharge");        //Forzamos el efecto de "opacity On" en el footer al cambiar de ruta
        footerItems.forEach((item) => {
            item.classList.remove("delayOnCharge");
            setTimeout(() => {
                item.classList.add("delayOnCharge");
            }, 0);
        })
    }, [thisLocation]);

    return (
        <>
            <div className='footerNetworksCont delayOnCharge flex'>
                <img src="/images/icons/instagram.png" className="footerNetworksIcons" alt="Instagram Icon" />
                <img src="/images/icons/facebook.png" className="footerNetworksIcons" alt="Instagram Icon" />
            </div>
            <p className='footerCopy delayOnCharge'>&copy; 2023 Diego Corrado</p>
        </>
    );
}

export default Footer;
