import React from 'react';
import DrawingCart from '../../../components/drawingCart/DrawingCart';
import BgParticles from '../../../components/bgParticles/BgParticles';
import aosDrawingCard from '../../../utils/aosDrawingCard';
import { useEffect, useState } from 'react';
import Carousel from '../../../components/carousel/Carousel';
import "./g2.css";

const G2 = () => {

    const [carousel, setCarousel] = useState();
    const [exitIcon, setExitIcon] = useState();

    useEffect(() => {

        aosDrawingCard();

        const showCarousel = (e) => {
            const cardsImgs = document.querySelectorAll(".drawingCardImg");
            const imgsRoutes = [];
            cardsImgs.forEach((card) => {
                imgsRoutes.push(card.src);
            });
            const imgSelect = e.target;
            const imgSelectPos = imgSelect.getAttribute("pos");
            const imgcarouselclass = imgSelect.getAttribute("imgcarouselclass");    // <---- Clase para la imagen del carousel
            setExitIcon( <img src="/images/icons/close.png" className='carouselCloseIcon' alt="Exit Icon" onClick={() => {setCarousel(); setExitIcon(); document.body.style.overflow = "initial"}}/> );
            setCarousel(<Carousel imgsRoutes={imgsRoutes} imgSelectPos={imgSelectPos} imgClass={imgcarouselclass}/>);
            document.body.style.overflow = "hidden";
        }

        const addListenners = () => {
            const cardsImgs = document.querySelectorAll(".drawingCardImg");
            cardsImgs.forEach((card, index) => {
                card.setAttribute("pos", index);
                card.addEventListener("click", (e) => {
                    showCarousel(e);
                })
            })
        }

        addListenners();
                
    }, []);
    
    return (
        <>
            {exitIcon}
            {carousel}
            <div className='contParticles'>       {/*    Particles entre divs para evitar scroll?? al cargar pagina  */}
                <BgParticles particlesColor="random" bgColor="#142747" speed={0.75} opacity={1} clase="" size={10}/>
            </div>
            <div className='contSeccion opacityOnCharge galleryPaddingTopx2 flex wrap'>
                <DrawingCart imageDir={"/images/drawings/g3/1.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/2.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/4.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/5.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/6.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/7.jpg"} imgClass="opl imgWithOutFilter" imgcarouselclass="imgWithOutFilter"/>
                <DrawingCart imageDir={"/images/drawings/g3/8.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/9.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/10.jpg"} imgClass="g2ImgsAdjust opl"/>
                <DrawingCart imageDir={"/images/drawings/g3/11.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/12.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/13.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/14.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/15.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/16.jpg"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/17.jpg"} imgClass="imgWithOutFilter" imgcarouselclass="imgWithOutFilter"/>
                <DrawingCart imageDir={"/images/drawings/g3/18.jpg"} imgClass="imgWithOutFilter opl" imgcarouselclass="imgWithOutFilter"/>
                <DrawingCart imageDir={"/images/drawings/g3/19.webp"} imgClass="g2ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g3/19.jpg"} imgClass="g2ImgsAdjust opr"/>
            </div>
        </>
    );
}

export default G2;
