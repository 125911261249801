// https://www.npmjs.com/package/react-spinners
// https://www.davidhu.io/react-spinners/

import PuffLoader from "react-spinners/PuffLoader";
import React from 'react';
import "./spinners.css";

const Spinners = () => {
    return (
        <div className="contSpinners flex">
            <PuffLoader color="orange" size={100}/>
        </div>
    );
}

export default Spinners;