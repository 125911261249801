import Swal from "sweetalert2";

const swalPopUp = (title, text, icon) => {

    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        confirmButtonColor: '#E9AB04',
        color: '#1c2541DF',
        scrollbarPadding: false,
        customClass: {
            confirmButton: 'sweetConfirmBoton',
        },
    })

}

export {swalPopUp};