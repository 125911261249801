import React from 'react';
import "./form.css";
import BgParticles from '../bgParticles/BgParticles';
import { useRef, useState } from 'react';
import Spinners from '../spinners/Spinners';
import { swalPopUp } from '../../utils/sweetAlert/swal';

const Form = () => {

    const formRef = useRef();
    const [spinner, setSpinner] = useState();

    const sendForm = async () => {
        const formData = new FormData(formRef.current);
        const data = Object.fromEntries(formData);

        if (data.name.trim() !== "" && data.email.trim() !== "" && data.message.trim() !== "") {
        
            try {
                setSpinner(<Spinners/>)
                const respJSON = await fetch ("/sendForm.php", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                const respOBJ = await respJSON.json();
                setSpinner()
                if (respOBJ.msg.includes("Error")) {
                    swalPopUp("Ops!", `${respOBJ.msg}, Intenta otra vez`, "error");
                } else {
                    const inputs = document.querySelectorAll(".inputsForm");
                    inputs.forEach((input) => input.value = "");
                    swalPopUp("Enviado!", "Mensaje Enviado con Exito, Gracias por Contactarme!", "success");
                } 
                
            } catch (err) {
                setSpinner(); 
                swalPopUp("Ops!", `Error al enviar el mensaje: ${err.message}`, "error");
            }

        } else {
            swalPopUp("Ops!", "Falta Ingresar Algún Dato", "warning");       
        }
    }

    return (
        <>
            {spinner}
            <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.25}/>
            <div className='contSeccion contactSeccion opacityOnCharge animation-duration1 flex column'>
                <form action="" className='contactFormCont' ref={formRef}>
                    <div className='contactEmailCont flex'>
                        <a className='contactEmailContInt flex' href="mailto:info@diegocorrado.com.ar">
                            <img src="/images/icons/email.png" alt="Email Icon" className='contactEmailIcon'/>
                            <p className='contactEmail'>info@diegocorrado.com.ar</p>
                        </a>
                    </div>
                    <input name="name" type="text" placeholder='Nombre' className='contactInputName inputsForm'/>
                    <input name="email" type="email" placeholder='e-mail' className='contactInputEmal inputsForm'/>
                    <textarea name="message" id="" cols="30" rows="10" placeholder='Mensaje' className='contactInputMessage inputsForm'></textarea>
                </form>
                <button className='contactButton flex' onClick={sendForm}>Enviar</button>
            </div>
        </>
    );
}

export default Form;
