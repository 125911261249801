import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import "./generalStyles.css";
import Inks from './pages/gallerys/inks/Inks';
import GallerysList from './pages/gallerysList/GallerysList';
import Footer from './components/footer/Footer';
import G1 from './pages/gallerys/g1/G1';
import G2 from './pages/gallerys/g2/G2.jsx';
import G3 from './pages/gallerys/g3/G3';
import G4 from './pages/gallerys/g4/G4';
import G5 from './pages/gallerys/g5/G5';
import G6 from './pages/gallerys/g6/G6';
import G7 from './pages/gallerys/g7/G7';
import G8 from './pages/gallerys/g8/G8';
import G9 from './pages/gallerys/g9/G9';
import G10 from './pages/gallerys/g10/G10';
import G11 from './pages/gallerys/g11/G11';
import IllustrationsIndex from './pages/illustrationsIndex/IllustrationsIndex';
import PhotographyIndex from './pages/photographyIndex/PhotographyIndex';
import ModelsIndex from './pages/modelsIndex/ModelsIndex';
import NavBar from './components/navBar/NavBar';
import Form from './components/form/Form';
import About from './pages/about/About';
import Press from './pages/press/Press';

function App() {

    return (
        <BrowserRouter>     
            <NavBar/>
            <Routes>
                <Route path="/" element={<Navigate to="/home" />} /> 
                <Route path="/home" element={<Home/>} />
                <Route path="/gallerys/inks" element={<Inks/>} /> 
                <Route path="/gallerylist" element={<GallerysList/>} />
                <Route path="/illustrationsindex" element={<IllustrationsIndex/>} />
                <Route path="/photographyindex" element={<PhotographyIndex/>} />
                <Route path="/modelsindex" element={<ModelsIndex/>} />
                <Route path="/gallerys/g1" element={<G1/>} />
                <Route path="/gallerys/g2" element={<G2/>} />
                <Route path="/gallerys/g3" element={<G3/>} />
                <Route path="/gallerys/g4" element={<G4/>} />
                <Route path="/gallerys/g5" element={<G5/>} />
                <Route path="/gallerys/g6" element={<G6/>} />
                <Route path="/gallerys/g7" element={<G7/>} />
                <Route path="/gallerys/g8" element={<G8/>} />
                <Route path="/gallerys/g9" element={<G9/>} />
                <Route path="/gallerys/g9b" element={<G10/>} />
                <Route path="/gallerys/g9c" element={<G11/>} />
                <Route path="/contact" element={<Form/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/press" element={<Press/>} />
                <Route path="*" element= {<Navigate to="/home" />} />
            </Routes>
            <Footer/>       
        </BrowserRouter>
    );
}

export default App;
