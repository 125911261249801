import React from 'react';
import "./about.css";
import BgParticles from '../../components/bgParticles/BgParticles';

const About = () => {
    return (
        <>
            <div className='contParticles'>
                <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.25}/>
            </div>
            <div className='contSeccion aboutSeccion galleryPaddingTopx2 opacityOnCharge animation-duration1 flex column'>
                <div className='aboutCont flex column'>
                    <h2 className='aboutTitulo'>Diego Corrado</h2> 

                    <div className='aboutBiografiaCont'>
                        <p>Bienvenidos a mi sitio, soy un artista autodidacta apasionado por la ilustración y la fotografía, originario de la localidad de Lomas de Zamora, en Buenos Aires, Argentina. Desde mis primeros días, el arte ha sido mi constante compañero y guía, y a través de los años, he explorado diversas formas de expresión para dar vida a mis ideas. </p>
                        <p>Desde temprana edad, me sentí atraído por las posibilidades infinitas que el arte ofrece. Experimenté con diferentes técnicas, desde la ilustración hasta la cerámica, cada una de ellas ampliando mis horizontes creativos. </p>
                        <p>La elección de una profesión representó un paso crucial en mi recorrido. A pesar de mi atracción innata por el arte, decidí enfocarme en el diseño industrial, ya que me intrigaba la forma en que esta disciplina fusiona la expresión artística con la destreza técnica. </p> 
                        <p>La literatura ha sido un constante compañero y fuente de inspiración en mi vida. Mis lecturas preferidas se inclinan hacia la ciencia ficción de autores como Karel Capek y Ballard, así como hacia el terror inmortalizado por Edgar Allan Poe y otros escritores hasta la década de 1940. </p>
                        <p>A lo largo de los años, mis intereses y temáticas artísticas evolucionaron, Desde mi fascinación por los dioramas desde mi infancia, pasando por la exploración de técnicas como tintas, lápices, el tradicional filete porteño y el pirograbado sobre madera, hasta la creación de ilustraciones en dioramas en cajas cerradas, acuarelas y técnicas mixtas. También incursioné en la escultura y he brindado apoyo técnico a otros artistas en sus proyectos. </p> 
                        <p>En los últimos 10 años, dos corrientes artísticas han marcado profundamente mi trayectoria. Por un lado, he crecido y me he desarrollado en el arte militar, empleando la técnica del scratch y, por otro lado, creando ilustraciones de gran formato utilizando lápices, pasteles y marcadores. </p>
                        <p>Espero que a través de mi trabajo pueda compartir con ustedes no solo mis creaciones, sino también el constante proceso de aprendizaje y desarrollo que impulsa mi arte. El camino creativo es siempre evolutivo. </p> 
                        <p>Los invito a explorar mi galería recordando las palabras de David Lynch, quien afirmaba que todo cuadro o película, toda ficción, debe presentar un mundo que se maneje honestamente con reglas propias, un mundo del cual uno pueda enamorarse y al que se pueda dejar hablar por sí mismo, imágenes como flashes de cuentos fantásticos, escenas mudas que no permiten reconstruir la historia completa, pero sí invitan a pensar que la historia es posible y que en todo caso transcurre en algún tiempo y lugar más allá del entendimiento. </p> 
                    </div>

                    <h2 className='aboutMuestrastitle'>Muestras</h2>

                    <div className='aboutMuestrasCont'>
                        <p>2008- Muestra "hojas en blanco" Centro cultural pasaje dardo rocha, La plata (muestra colectiva)</p>
                        <p>2008- Muestra "Interfaces dialogo visuales entre regiones: la plata, corrientes" Museo provincial de Bellas Artes, la plata</p>
                        <p>2008- Muestra "Interfaces dialogo visuales entre regiones: la plata, corrientes" Centro cultural MORS, provincia de corrientes.</p>
                        <p>2008- Muestra "Interfaces dialogo visuales entre regiones la plata:, corrientes" Fondo nacional de las Artes, Alsina 673 CABA</p>
                        <p>2007- Muestra "Valijas y equipajes" Pasaje dardo rocha, 50 e6 y 7, la plata (muestra colectiva)</p>
                        <p>2006- Muestra "aparatos de vuelo rasante" Centro cultural las mil y una artes, medrano 645 CABA, exposicion individual</p>
                        <p>2004- Muestra "Cazador de sueños" Centro cultural las mil y una artes, medrano 645 CABA, exposicion individual</p>
                        <p>2001- Muestra "sueños y pesadillas"  A.C.E.S.E boedo 352, lomas de zamora (exposicion individual)</p>
                        <p>2000- A.C.E.S.E boedo 352, lomas de zamora (exposicion colectiva)</p>
                        <p>1999- Colegio de arquitectos sucursal banfield (muestra colectiva)</p>
                        <p>1999- municipalidad de Quilmes (muestra colectiva)</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;
