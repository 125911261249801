import React, { useState, useEffect } from 'react';
import "./carousel.css";

const Carousel = ({imgsRoutes, imgSelectPos, imgClass}) => {

    const [imgSrc, setImgSrc] = useState();
    const [imgPos, setimgPos] = useState();

    useEffect(() => {
        setimgPos(parseInt(imgSelectPos));
        setImgSrc(imgsRoutes[parseInt(imgSelectPos)]);   
                   
        const carousel = document.querySelector(".carouselCont");    //Cambia el color del fondo del carousel en galerias de ilustraciones para chicos
        const actualUrl = window.location.href;
        if (actualUrl.includes("g1") || actualUrl.includes("g2")) {
            carousel.classList.add("backGroundChildsCarousel");
        } else {
            carousel.classList.remove("backGroundChildsCarousel");
        }        
     // eslint-disable-next-line
    }, []);
        
    const nextImage = (opc) => {
        if (opc) {
            imgPos === imgsRoutes.length - 1 ? setimgPos(0) : setimgPos(imgPos + 1);
        } else {
            imgPos === 0 ? setimgPos(imgsRoutes.length - 1) : setimgPos(imgPos - 1);
        }
    }
    
    useEffect(() => {
        setImgSrc(imgsRoutes[imgPos]);
        const carouselImage = document.querySelector(".carouselActualImg");
        carouselImage.classList.remove("carouselActualImgOpOn");
        setTimeout(() => {
            carouselImage.classList.add("carouselActualImgOpOn");
        }, 0);
        // eslint-disable-next-line
    }, [imgPos]);
      
    return (
        <div className='carouselCont flex'>
            <img src="/images/icons/next.png" className='carouselNextIcon' alt="Next Icon" onClick={() => nextImage(true)}/>
            <img src="/images/icons/next.png" className='carouselPrevIcon' alt="Prev Icon" onClick={() => nextImage(false)}/>
            <img src={imgSrc} className={`carouselActualImg carouselActualImgOpOn ${imgClass}`} alt="Carousel Img" />
        </div>        
    );
}

export default Carousel;
