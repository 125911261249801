import React from 'react';
import "./modelsIndex.css";
import BgParticles from '../../components/bgParticles/BgParticles';
import { Link } from 'react-router-dom';

const ModelsIndex = () => {
    return (
        <>
            <div className='contParticles'>      
                <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.25}/>
            </div>
            <div className='contSeccion contIllustrationsIndex opacityOnCharge animation-duration1 flex wrap'>
                <Link to="/gallerys/g4" className='illustrationsIndexImgCont illustrationsIndexImgContModels flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>01</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDescAviones'>"Ilustraciones"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt modelsImgCont'><img src="/images/drawings/g5/16.jpg" className='illustrationsIndexImg imgg4contrast opl' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1 illustrationsIndexFrase1Adjust'> 
                            "Los recuerdos tienen un enorme poder de permanencia, pero como los sueños, 
                            prosperan en la oscuridad, sobreviviendo durante décadas en las aguas profundas de nuestra mente como naufragios en el fonde del mar"  
                        </span>
                        <span>J. G. Ballard</span> <br />
                    </p>
                </Link>
                <Link to="/gallerys/g8" className='illustrationsIndexImgCont illustrationsIndexImgContModels flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>02</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDescModelos'>"Modelos a escala hechos a mano"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt modelsImgCont'><img src="/images/drawings/g9/7j.jpg" className='illustrationsIndexImg modelsImg1' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'> 
                            "Todos los hombres sueñan, pero no por igual. Los que sueñan de noche en los polvorientos recovecos de su mente
                            ,despertar en el día para encontrar que era vanidad, pero los soñadores del día son hombres peligrosos, porque pueden actuar sobre
                            sus sueños con los ojos abiertos, para hacerlos posibles" 
                        </span>
                        <span>T. E. Lawrence </span>
                    </p>
                </Link>
            </div>
        </>
    );
}

export default ModelsIndex;
