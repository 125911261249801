import React, { useState, useEffect } from 'react';
import "./press.css";
import BgParticles from '../../components/bgParticles/BgParticles';

const ImgCard = ({src}) => {
    return (
        <div className='imgCardCont opacityOnCharge animation-duration035 flex'>
            <img className="imgCard" src={src} alt="Img Card" />
        </div>
    )
}

const Press = () => {

    const [img, setImg] = useState();
    const [close, setClose] = useState();

    useEffect(() => {
        const images = document.querySelectorAll(".pressImg");
        images.forEach((image) => {
            image.addEventListener("click", (e) => {
                setImg(<ImgCard src={e.target.src}/>);
                setClose(<img className='pressImgClose' src="/images/icons/close.png" alt="Close Icon" onClick={() => {setImg(); setClose(); document.body.style.overflow = "initial"}}/>);
                document.body.style.overflow = "hidden";
            })
        })
    }, []);
    
    return (
        <>
            {img}
            {close}
            <div className='contParticles'>
                <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.25}/>
            </div>
            <div className='contSeccion pressSeccion galleryPaddingTopx2 opacityOnCharge animation-duration1 flex column'>
                <p className='pressTextos'> 
                    Libro Interfaces, Dialogos Visuales entre regiones. <br />
                    Libro editado por el Fondo nacional de las Artes. Año 2010, 330 paginas. <br />
                </p>
                <div className='pressImgsCont flex column'>
                    <div className='pressImgsGropu1 flex'>
                        <div className='pressImgCont pressImg1Cont'><img className="pressImg" src="/images/press/1.jpg" alt="Press" /></div>
                        <div className='pressImgCont pressImg2Cont'><img className="pressImg" src="/images/press/2.jpg" alt="Press" /></div>
                    </div>
                </div>    

                <div className='pressImgsCont flex column'>    
                    <div className='pressImgsGropu2 flex'>
                        <div className='pressImgCont pressImg3Cont'><img className="pressImg" src="/images/press/3.jpg" alt="Press" /></div>
                        <div className='pressImgCont pressImg4Cont'><img className="pressImg" src="/images/press/4.jpg" alt="Press" /></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Press;
