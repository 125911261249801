import React from 'react';
import "./illustrationsIndex.css";
import BgParticles from '../../components/bgParticles/BgParticles';
import { Link } from 'react-router-dom';

const IllustrationsIndex = () => {
    return (
        <>
            <div className='contParticles'>      
                <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.25}/>
            </div>
            <div className='contSeccion contIllustrationsIndex opacityOnCharge animation-duration1 flex wrap'>
                <Link to="/gallerys/g1" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>01</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDesc1'>"Ilustraciones 1"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt'><img src="/images/drawings/g4/1.jpg" className='illustrationsIndexImg imgg4contrast' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1 illustrationsIndexFrase1Adjust'> "Que es lo que se supone que no debo sentir? Como un ave en pleno vuelo en un cielo henchido, mi mente es desgarrada por el relámpago, mientras vuela escapando del trueno..."  </span>
                        <span>Sarah Kane, "4.41 psicosis"</span> <br />
                    </p>
                </Link>
                <Link to="/gallerys/g2" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>02</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDesc2'>"Ilustraciones 2"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt'><img src="/images/drawings/g3/1.jpg" className='illustrationsIndexImg' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'> "Entonces el Tiempo se vuelve el Compañero de la Luz y deja de ser el enemigo de los Sueños" </span>
                        <span> Ray Bradbury, "Ahmed y las maquinas del olvido" </span>
                    </p>
                </Link>
                <Link to="/gallerys/g3" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>03</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDesc3'>"Nativos Americanos"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt'><img src="/images/drawings/g2/1.jpg" className='illustrationsIndexImg' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'> “Libro de artista sobre indios Americanos y algunos temas más” </span>
                        <span></span>
                    </p>
                </Link>
                <Link to="/gallerys/g9" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>04</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDesc4'>Tapa para el disco "Yo Cabrón" <br /> de La Misteriosa</p>
                    </div>
                    <div className='illustrationsIndexImgContInt'><img src="/images/drawings/g10/1.jpg" className='illustrationsIndexImg' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'> “Ilustración tradicional con postprocesado digital” </span>
                        <span></span>
                    </p>
                </Link>
                <Link to="/gallerys/g9b" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>05</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDesc5'>"Proyecto mi Argentina ilustrada"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt'><img src="/images/drawings/g11/1.jpeg" className='illustrationsIndexImg' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'>"Proyecto mí Argentina Ilustrada: lugares que me gustan de la Argentina ilustrados por mí" </span>
                        <span></span>
                    </p>
                </Link>
                <Link to="/gallerys/g9c" className='illustrationsIndexImgCont flex column'>
                    <div className='illustrationsIndexName flex column'>
                        <p className='illustrationsIndexGallery'>Galería</p>
                        <p className='illustrationsIndexNumber'>06</p>
                        <p className='illustrationsIndexDesc illustrationsIndexDesc6'>"Lapiz (1997-1999)"</p>
                    </div>
                    <div className='illustrationsIndexImgContInt'><img src="/images/drawings/g12/2.jpg" className='illustrationsIndexImg' alt="Gallery Img" /></div>
                    <p className='illustrationsIndexFraseCont flex column'> 
                        <span className='illustrationsIndexFrase1'>"Cuando el Mundo sea reducido a un único bosque negro para nuestros cuatro ojos pasmados, a una playa para dos niños fieles, a una casa musical para nuestra clara simpatía, yo te econtraré."</span>
                        <span>Arthur Rimbaud</span>
                    </p>
                </Link>
            </div>
        </>
    );
}

export default IllustrationsIndex;
