import "./home.css";
import BgParticles from '../../components/bgParticles/BgParticles';
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {

    useEffect(() => {                                   //Para evitar que aparezca el scroll al iniciar la animación de entrada
        document.body.style.overflow = "hidden";
        setTimeout(() => {
            document.body.style.overflow = "initial";
        }, 1000);
    }, []);
       
    return (
        <>
            <BgParticles particlesColor="#fa5725" bgColor="#000000" speed={1.5} opacity={0.5}/>
            <div className='contSeccion diegoSeccion opacityOnCharge flex column'>
                <h1 className='homeTitulo toDownOnCharge'>Diego Corrado</h1>
                <div className='homeImgCont'>
                    <div className='homeSubtitle toRightOnCharge'><p>Artist</p></div>
                    <img src="/images/home/diego2.jpg" className='homeImgDiego toLeftOnCharge' alt="Diego Corrado" />
                </div>
                <Link to="/gallerylist" className='homeMainButtonCont toUpOnCharge'><div className='homeMainButton flex'>EXPLORAR</div></Link>
            </div>
        </>
    );
}

export default Home;
