import React from 'react';
import DrawingCart from '../../../components/drawingCart/DrawingCart';
import BgParticles from '../../../components/bgParticles/BgParticles';
import aosDrawingCard from '../../../utils/aosDrawingCard';
import { useEffect, useState } from 'react';
import Carousel from '../../../components/carousel/Carousel';
import "./g8.css";

const G8 = () => {

    const [carousel, setCarousel] = useState();
    const [exitIcon, setExitIcon] = useState();

    useEffect(() => {

        aosDrawingCard();

        const showCarousel = (e) => {
            // const cardsImgs = document.querySelectorAll(".drawingCardImg");
            // const imgsRoutes = [];
            // cardsImgs.forEach((card) => {
            //     imgsRoutes.push(card.src);
            // });
            // const imgSelect = e.target;
            // const imgSelectPos = imgSelect.getAttribute("pos");

            const imgRoutesNodes = e.target.parentNode.parentNode.nextSibling.childNodes;
            const imgsRoutes = [];
            for (const img of imgRoutesNodes) {
                imgsRoutes.push(img.src);
            }

            console.log(imgsRoutes)

            setExitIcon( <img src="/images/icons/close.png" className='carouselCloseIcon' alt="Exit Icon" onClick={() => {setCarousel(); setExitIcon(); document.body.style.overflow = "initial"}}/> );
            setCarousel(<Carousel imgsRoutes={imgsRoutes} imgSelectPos={0} imgClass="g3ImgsAdjust"/>);
            document.body.style.overflow = "hidden";
        }
        
        const addListenners = () => {                                                                   //Agrega Listenners en imagenes de la galeria
            const cardsImgs = document.querySelectorAll(".drawingCardImg");
            cardsImgs.forEach((card, index) => {
                    card.addEventListener("click", (e) => {
                    showCarousel(e);
                })
            })                                                  

            const modelsImgRoutesConts = document.querySelectorAll(".modelsImgRoutesCont");             //Agrega numeros de posicion a las imagenes a mostrar en el carousel
            modelsImgRoutesConts.forEach((cont) => {
                const imgsRoutes = cont.childNodes;
                imgsRoutes.forEach((img, index) => {
                    img.setAttribute("pos", index);
                })
            })
        }
        
        addListenners();
                
    }, []);
    
    return (
        <>
            {exitIcon}
            {carousel}
            <div className='contParticles'>       {/*    Particles entre divs para evitar scroll?? al cargar pagina  */}
                <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.5}/>
            </div>
            <div className='contSeccion opacityOnCharge galleryPaddingTopx2 flex wrap'>
                <DrawingCart imageDir={"/images/drawings/g9/1a.jpg"} imgClass="g8ImgsAdjust imdAdjustModels1" landscape={true} title="ALKETT MINENRAUMER"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/1a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/1b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/1c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/1d.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/1e.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/2a.jpg"} imgClass="g8ImgsAdjust imdAdjustModels2" landscape={true} title="Aml 60 legion Española en el sahara"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/2a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/2b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/2c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/2d.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/3a.jpg"} imgClass="g8ImgsAdjust imdAdjustModels3" landscape={true} title="aml 90 ejercito argentino"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/3a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/3b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/3c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/3d.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/4a.jpg"} imgClass="g8ImgsAdjust imdAdjustModels3" landscape={true} title="aml 90 plunket"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/4a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/4b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/4c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/4d.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/5a.jpg"} imgClass="g8ImgsAdjust" landscape={true} title="GARFORD PUTILOV ARMORED CAR"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/5a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5d.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5e.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5f.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5g.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5h.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5i.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5j.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5k.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5l.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/5m.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/6a.jpg"} imgClass="g8ImgsAdjust imdAdjustModels5" landscape={true} title="nave de cirujeo intergalactica"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/6a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6d.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6e.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6f.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6g.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6h.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/6i.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/7a.jpg"} imgClass="g8ImgsAdjust imdAdjustModels4" landscape={true} title="panhard crab"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/7a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7d.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7e.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7f.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7g.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7h.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7i.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7j.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7k.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7l.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7m.jpg" alt="Cards Source" /> 
                    <img src="/images/drawings/g9/7n.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/7o.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/8a.jpg"} imgClass="g8ImgsAdjust" landscape={true} title="russian dragsine"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/8a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/8b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/8c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/8d.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/8e.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/9a.jpg"} imgClass="g8ImgsAdjust" landscape={true} title="SDKFZ 222"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/9a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/9b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/9c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/9d.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/9e.jpg" alt="Cards Source" />
                </div>
                <DrawingCart imageDir={"/images/drawings/g9/91a.jpg"} imgClass="g8ImgsAdjust" landscape={true} title="XF 85 GOBLIN"/>
                <div className='modelsImgRoutesCont'>
                    <img src="/images/drawings/g9/91a.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/91b.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/91c.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/91d.jpg" alt="Cards Source" />
                    <img src="/images/drawings/g9/91e.jpg" alt="Cards Source" />
                </div>
            </div>       
        </>
    );
}

export default G8;
