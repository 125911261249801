import React from 'react';
import "./gallerysList.css";
import BgParticles from '../../components/bgParticles/BgParticles';
import { Link } from 'react-router-dom';

const GallerysList = () => {
    return (
        <>
            <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.25}/>
            <div className='contSeccion seccionGallerysListCont opacityOnCharge animation-duration1 flex column'>
                <div className='seccionGallerysListSquare flex column'>
                    <div className='galleryListCornerDer'></div>
                    <div className='seccionGallerysList flex column'>
                        <Link to="/illustrationsindex"><p>Ilustraciones</p></Link>
                        <Link to="/gallerys/inks"><p>Tintas</p></Link>
                        <Link to="/modelsindex"><p>Arte Militar</p></Link>
                        <Link to="/photographyindex"><p>Fotografía</p></Link>
                    </div>
                    <div className='galleryListCornerIzq'></div>
                </div>
                <Link to="/" className='galleysListHomeOptionCont'><p >Home</p></Link>
            </div>
        </>
    );
}

export default GallerysList;
