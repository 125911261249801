import React from 'react';
import DrawingCart from '../../../components/drawingCart/DrawingCart';
import BgParticles from '../../../components/bgParticles/BgParticles';
import aosDrawingCard from '../../../utils/aosDrawingCard';
import { useEffect, useState } from 'react';
import Carousel from '../../../components/carousel/Carousel';
import "./g6.css";

const G6 = () => {

    const [carousel, setCarousel] = useState();
    const [exitIcon, setExitIcon] = useState();

    useEffect(() => {

        aosDrawingCard();

        const showCarousel = (e) => {
            const cardsImgs = document.querySelectorAll(".drawingCardImg");
            const imgsRoutes = [];
            cardsImgs.forEach((card) => {
                imgsRoutes.push(card.src);
            });
            const imgSelect = e.target;
            const imgSelectPos = imgSelect.getAttribute("pos");
            setExitIcon( <img src="/images/icons/close.png" className='carouselCloseIcon' alt="Exit Icon" onClick={() => {setCarousel(); setExitIcon(); document.body.style.overflow = "initial"}}/> );
            setCarousel(<Carousel imgsRoutes={imgsRoutes} imgSelectPos={imgSelectPos} imgClass="g3ImgsAdjust"/>);
            document.body.style.overflow = "hidden";
        }

        const addListenners = () => {
            const cardsImgs = document.querySelectorAll(".drawingCardImg");
            cardsImgs.forEach((card, index) => {
                card.setAttribute("pos", index);
                card.addEventListener("click", (e) => {
                    showCarousel(e);
                })
            })
        }

        addListenners();
                
    }, []);
    
    return (
        <>
            {exitIcon}
            {carousel}
            <div className='contParticles'>       {/*    Particles entre divs para evitar scroll?? al cargar pagina  */}
                <BgParticles particlesColor="#ffa836" bgColor="#171e2c" speed={0.5} opacity={0.5}/>
            </div>
            <div className='contSeccion opacityOnCharge galleryPaddingTopx2 flex wrap'>
                <DrawingCart imageDir={"/images/drawings/g7/1.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/2.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/3.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/4.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/5.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/6.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/7.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/8.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/9.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/10.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/11.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/13.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/14.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/15.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/16.jpg"} imgClass="g3ImgsAdjust opl"/>
                <DrawingCart imageDir={"/images/drawings/g7/17.jpg"} imgClass="g3ImgsAdjust"/>
                <DrawingCart imageDir={"/images/drawings/g7/18.jpg"} imgClass="g3ImgsAdjust"/>
            </div>
        </>
    );
}

export default G6;
